export function overrideIsSelected() {
  return {
    mounted() {
      //console.log(this.$options.components.Treeselect);

      const originalMounted = this.$options.components.Treeselect.mounted;

      this.$options.components.Treeselect.mounted = function () {
        const self = this;

        if (originalMounted) {
          originalMounted.call(this);
        }

        this.updateHiddenInputField = function () {
          const formElement = self.$el.closest('form');
          if (formElement) {
            if(self.name != undefined){
              const existingInputs = formElement.querySelectorAll(`input[name^="${self.name.replace('[]', '')}"]`);
              existingInputs.forEach(input => {
                if (input.parentNode === formElement) {
                  formElement.removeChild(input);
                }
              });
            }
            if (Array.isArray(self.modelValue)) {
                self.modelValue.forEach((value, index) => {
                const hiddenInput = document.createElement('input');
                hiddenInput.type = 'hidden';
                hiddenInput.name = self.name;
                hiddenInput.value = value;
                formElement.appendChild(hiddenInput);
                });
             } else if(!self.disabled){
                const hiddenInput = document.createElement('input');
                hiddenInput.type = 'hidden';
                hiddenInput.name = self.name;
                hiddenInput.value = self.modelValue;
                formElement.appendChild(hiddenInput);
              }
          }
        };
        this.$watch('modelValue', (newValue) => {
          this.updateHiddenInputField();
        }, { deep: true, immediate: true });

        this.updateHiddenInputField();

        // console.log(this.name, this.modelValue);
      };
    },
  };
}
